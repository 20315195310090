import { generateSlice } from '../../../store/GenerateSlice'

export const RenewalServiceSlice = generateSlice({
  name: 'RenewalServiceSlice',
  initialState: {
    searchResult: null,
    newPrintables: [],
    paidInvoiceId: null,
    ownerData: null,
    isValidateApp: null,

    isValidate: false,
    invoiceData: null,
  },
})
