export const drawerWidth = 300
export const inactivityTimeout = 10 * 60 * 1000 // 60 sec 1000 mill
export const supportedFilesFormat = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'application/pdf']
export const maxFilesSizes = {
  attachReceipt: 500 * 1000,
  tin: { photo: 500 * 1000, signature: 500 * 1000, attach: 500 * 1000 },
  dl: { photo: 500 * 1000, signature: 500 * 1000, attach: 500 * 1000 },
  vr: 500 * 1000 /*size in Bytes*/,
}
const rootServerURI = window.location.origin
const FrontURL = {
  Dev: 'http://192.168.1.123/TMSApi/',
  ProdUat: 'http://ltm-appuat/',
  DubaiArchana: 'http://192.168.0.202/',
  DubaiNigDemo: 'http://86.98.4.21:105/',
  DubaiNigDemoDynamic: rootServerURI,
  DubaiDemo: 'http://86.98.4.21:85/',
  DubaiDemoDynamic: rootServerURI,
  DubaiUat: 'http://86.98.4.21:83/',
  DubaiUatDynamic: rootServerURI,
  DubaiDev: 'http://86.98.4.21:81/',
}

const tmsAPIURL = {
  DevLocal: 'https://localhost:44366/',
  DevLawEnforcement: 'http://DESKTOP-M3OOB5U:55497/',
  Dev: 'http://192.168.1.123/TMSAPI/',
  Dev4400: 'http://DESKTOP-M3OOB5U:4400/TMSAPI/',
  ProdUat: 'http://ltm-appuat/tmsapi/',
  DubaiArchana: 'http://192.168.0.202/TMSAPI/',
  DubaiNigDemo: 'http://86.98.4.21:105/TMSAPI/',
  DubaiNigDemoDynamic: rootServerURI + '/TMSAPI/',
  DubaiDemo: 'http://86.98.4.21:85/TMSAPI/',
  DubaiDemoDynamic: rootServerURI + '/TMSAPI/',
  DubaiUat: 'http://86.98.4.21:83/TMSUatApi/',
  DubaiUatDynamic: rootServerURI + '/TMSUatApi/',
  DubaiDev: 'http://86.98.4.21:81/TMSDevApi/',
}
const tmsOnlineAPIURL = {
  DevLocal: 'http://20.9.139.2/TMSOnline/',
  DevLawEnforcement: 'http://DESKTOP-M3OOB5U:55497/',
  Dev: 'http://45.246.204.69/TMSOnline/',
  Dev4400: 'http://DESKTOP-M3OOB5U:4400/TMSAPI/',
  ProdUat: 'http://ltm-appuat/tmsapi/',
  DubaiArchana: 'http://192.168.0.202/TMSAPI/',
  DubaiNigDemo: 'http://86.98.4.21:105/TMSAPI/',
  DubaiNigDemoDynamic: rootServerURI + '/TMSOnline/',
  DubaiDemo: 'http://86.98.4.21:85/TMSAPI/',
  DubaiDemoDynamic: rootServerURI + '/TMSAPI/',
  DubaiUat: 'http://86.98.4.21:83/TMSUatApi/',
  DubaiUatDynamic: rootServerURI + '/TMSUatApi/',
  DubaiDev: 'http://86.98.4.21:81/TMSDevApi/',
}
const securityAPIURL = {
  DevLocal: 'http://localhost:55500/',
  DevLawEnforcement: 'http://DESKTOP-M3OOB5U:55500/',
  Dev: 'http://192.168.1.123/TMSAuth/',
  Dev4400: 'http://DESKTOP-M3OOB5U:4400/TMSAuth/',
  ProdUat: 'http://ltm-appuat/tmsauth/',
  DubaiArchana: 'http://192.168.0.202/TMSAuth/',
  DubaiNigDemo: 'http://86.98.4.21:105/TMSAuth/',
  DubaiNigDemoDynamic: rootServerURI + '/TMSAuth/',
  DubaiDemo: 'http://86.98.4.21:85/TMSAuth/',
  DubaiDemoDynamic: rootServerURI + '/TMSAuth/',
  DubaiUat: 'http://86.98.4.21:83/TMSUatAuth/',
  DubaiUatDynamic: rootServerURI + '/TMSUatAuth/',
  DubaiDev: 'http://86.98.4.21:81/TMSDevAuth/',
}
const NotificationIURL = {
  DevLocal: 'http://localhost:60703/',
  Dev: 'http://localhost:60703/',
  DubaiNigDemo: 'http://86.98.4.21:105/TMSNotification/',
  DubaiNigDemoDynamic: rootServerURI + '/TMSNotification/',
  DubaiDev: 'http://86.98.4.21:81/TMSDevNotification/',
}
const reportAPIURL = {
  DevLocal: 'https://localhost:44376/',
  Dev: 'http://45.246.204.69/TMSOnlineReport/',
  ProdUat: 'http://ltm-appuat/tmsreport/',
  DubaiArchana: 'http://192.168.0.202/TMSReport/',
  DubaiNigDemo: 'http://86.98.4.21:105/TMSReport/',
  DubaiNigDemoDynamic: rootServerURI + '/TMSReport/',
  DubaiDemo: 'http://86.98.4.21:85/TMSReport/',
  DubaiDemoDynamic: rootServerURI + '/TMSReport/',
  DubaiUat: 'http://86.98.4.21:83/TMSUatReport/',
  DubaiUatDynamic: rootServerURI + '/TMSUatReport/',
  DubaiDev: 'http://86.98.4.21:81/TMSDevReport/',
}
const printServerAPIURL = {
  DevLocal: 'http://localhost:2303/',
  Dev: 'http://192.168.1.123/TMSprint/',
  ProdUat: 'http://ltm-appuat/tmsprintmanager/',
  DubaiArchana: 'http://192.168.0.202/TMSPrint/',
  DubaiNigDemo: 'http://86.98.4.21:105/TMSPrint/',
  DubaiNigDemoDynamic: rootServerURI + '/TMSPrint/',
  DubaiDemo: 'http://86.98.4.21:85/TMSPrint/',
  DubaiDemoDynamic: rootServerURI + '/TMSPrint/',
  DubaiUat: 'http://86.98.4.21:83/TMSUatPrint/',
  DubaiUatDynamic: rootServerURI + '/TMSUatPrint/',
  DubaiDev: 'http://86.98.4.21:81/TMSDevPrint/',
}
export const currentEnvURL = {
  tmsApi: tmsOnlineAPIURL.DevLocal,
  authApi: securityAPIURL.DevLocal,
  printServerApi: printServerAPIURL.DevLocal,
  reportApi: reportAPIURL.Dev,
  FrontURL: FrontURL.Dev,
  NotificationApi: NotificationIURL.Dev,
}
export const LOCAL_STORAGE_CONSTANT = {
  TOKEN: 'token',
  USERNAME: 'username',
  AVATAR: 'avatar',
  USERDATA: 'userData',
}
export const seerbitOptions = {
  public_key: 'SBTESTPUBK_z7dgw267cmTP4vhCrSk70o3ySefySy7k',
  // amount: 100,
  currency: 'NGN',
  email: 'ahmed.ramadan@ditech.ae',
  // full_name: 'Sam Smart',
  // mobile_no: '081234566789',
  // description: 'test',
  productId: 'service id',
  setAmountByCustomer: false, //optional field. Set to true to allow customer set the amount
  tokenize: false,
  planId: '',
  // customization: {
  //   theme: {
  //     border_color: '#000000',
  //     background_color: '#004C64',
  //     button_color: '#0084A0',
  //   },
  //   // payment_method: ['card', 'account', 'transfer', 'wallet', 'ussd'],
  //   display_fee: true, // true
  //   display_type: 'embed', //inline
  //   // logo: 'logo_url | base64',
  // },
}
export const RECAPTCHA_SITE_KEY = '6LctoEwqAAAAAOwc_NALIczaRspkP8ehydiubcb2' //menna'6LeM1q8oAAAAAM0lY1FwLvhlql-fX-HhMcFxdD5n'
