import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import Page404 from '../pages/Page404/Page404.styles'
import DefaultLayout from '../layouts/DefaultLayout/DefaultLayout.styles'
import ProtectedRoute from '../components/common/ProtectedRoute/ProtectedRoute'
const SearchInvoice  = React.lazy(()=> import('../pages/invoice/SearchInvoice'))
const Login = React.lazy(()=> import('../pages/Login/Login.styles'))
const RenewalService = React.lazy(() => import('../pages/invoice/RenewalService'))

const Routes = () => {
  return useRoutes([
    {
      path: '/',
      element: <DefaultLayout />,
      children: [
        {
          path: '/',
          exact: true,
          element: <Navigate to="/renewal-service" />,
        },
        // {
        //   path: 'login',
        //   exact: true,
        //   element: <Login />,
        // },
        {
          path: 'renewal-service',
          element: <RenewalService />,
          exact: true,
        },
        // {
        //   path: 'search-invoice',
        //   element: (
        //     // <ProtectedRoute>
        //       <SearchInvoice />
        //     // </ProtectedRoute>
        //   ),
        //   exact: true,
        // },
      ],
    },

    { path: '/404', element: <Page404 /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ])
}

export default Routes
