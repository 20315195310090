import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
// import StyledAppBarMain from './components/AppBarMain/AppBarMain.styles'
// import StyledAppDrawer from './components/AppDrawer/AppDrawer.styles'
import { Main } from './DefaultLayout.styles'
// import Logic from './logic'
import LoadingDotsIcon from '../../components/common/LoadingDotsIcon/LoadingDotsIcon.styles'

const DefaultLayout = () => {
  // const { mobileOpen, openPersistentDrawer, handlePersistentDrawerOpen, handlePersistentDrawerClose, handleDrawerToggle } = Logic()
  // const state = useSelector((state) => {
  //   const { modulePages, cacheData, favMenu } = state.app
  //   return { modulePages, cacheData, favMenu }
  // }, shallowEqual)

  return (
    <Box sx={{ display: 'flex' }}>
      {/* <StyledAppBarMain handleDrawerToggle={handleDrawerToggle} openPersistentDrawer={openPersistentDrawer} handlePersistentDrawerOpen={handlePersistentDrawerOpen} /> */}
      {/* <StyledAppDrawer handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} openPersistentDrawer={openPersistentDrawer} handlePersistentDrawerClose={handlePersistentDrawerClose} /> */}

      <Main>
        <Suspense fallback={<LoadingDotsIcon />}>
          <Outlet />
        </Suspense>
      </Main>
    </Box>
  )
}

export default DefaultLayout
