import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { appSlice } from './AppSlice'
import { RenewalServiceSlice } from '../pages/invoice/RenewalService/RenewalServiceSlice'
import { SearchInvoiceSlice } from '../pages/invoice/SearchInvoice/SearchInvoiceSlice'

const reducer = combineReducers({
  [appSlice.name]: appSlice.reducer,
  [RenewalServiceSlice.name]: RenewalServiceSlice.reducer,
  [SearchInvoiceSlice.name]: SearchInvoiceSlice.reducer,
})

export default configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
